<template>
  <div class="app-body">
    <div class="a-flex-rsbc a-ptb-15">
      <span class="a-fs-16 a-fw-700">经营分析</span>
      <div class="a-flex-rcc">
        <le-export-btn :page-param="pageParam"></le-export-btn>
      </div>
    </div>
    <el-card class="el-main">
      <le-search-form id="leSearch" @reset="handlerRest" @search="handlerSearch">
        <le-company-under-select
          v-model="pageParam.params.ownerCompanyId"
          label="站点所属商户"
        ></le-company-under-select>
        <le-date-range
          label="站点开通时间"
          :min-date.sync="pageParam.params.stationOpenStartDate"
          :max-date.sync="pageParam.params.stationOpenEndDate"
        />
        <le-input-price-range
          label="站点累计收益"
          :min-data.sync="minHisOrderAmount"
          :max-data.sync="maxHisOrderAmount"
          placeholder="金额(元)"
        />
        <le-input-num-range
          label="站点累计订单"
          :min-data.sync="pageParam.params.minHisOrderNum"
          :max-data.sync="pageParam.params.maxHisOrderNum"
          placeholder="订单量"
        />
        <le-input-price-range
          label="站点订单均价"
          :min-data.sync="minAvgDeviceOrderAmount"
          :max-data.sync="maxAvgDeviceOrderAmount"
          placeholder="金额(元)"
        />
        <le-select-local-search
          v-model="pageParam.params.statIncome"
          label="统计范围"
          :options="optionsStatIncome"
        />
      </le-search-form>
      <le-pagview :page-param="pageParam" @setData="setTableData">
        <el-table
          v-sticky="{ top: 0, parent: '.el-card__body' }"
          :data="tableData"
          :highlight-current-row="true"
          style="width: 100%"
        >
          <el-table-column prop="incomeName" label="项目名称" min-width="110">
            <template slot-scope="{ row }">
              <span class="a-c-blue font-point" @click="toBusinessAnalysisInfo(row)">
                {{ row.incomeName ? row.incomeName : "-" }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="stationName" label="总营业额(元)" min-width="168">
            <template slot-scope="{ row }">
              <span>￥{{ row.hisOrderAmount ? (row.hisOrderAmount / 100).toFixed(2) : 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="companyName" label="30天营业额(元)" min-width="152">
            <template slot-scope="{ row }">
              <span>
                ￥{{ row.day30OrderAmount ? (row.day30OrderAmount / 100).toFixed(2) : 0 }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="onlineStatus" label="30天单桩收益(元)" min-width="152">
            <template slot-scope="{ row }">
              <span>
                ￥{{ row.perDeviceAmount30 ? (row.perDeviceAmount30 / 100).toFixed(2) : 0 }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="历史单桩收益(元)" min-width="152">
            <template slot-scope="{ row }">
              <span>￥{{ row.perDeviceAmount ? (row.perDeviceAmount / 100).toFixed(2) : 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="本月营业额(元)" min-width="152">
            <template slot-scope="{ row }">
              <span>
                ￥{{ row.monthOrderAmount ? (row.monthOrderAmount / 100).toFixed(2) : 0 }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="deviceType" label="本月单桩收益(元)" min-width="152">
            <template slot-scope="{ row }">
              <span>
                ￥{{ row.perDeviceAmountMonth ? (row.perDeviceAmountMonth / 100).toFixed(2) : 0 }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="在线(台)" min-width="152">
            <template slot-scope="{ row }">
              <span>{{ row.onlineDeviceNum ? row.onlineDeviceNum : 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="正在充电设备(台)" min-width="152">
            <template slot-scope="{ row }">
              <span>{{ row.usingDeviceNum ? row.usingDeviceNum : 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="操作" width="100" fixed="right">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" content="详情" placement="top">
                <img
                  src="../../assets/icon/option-detail.png"
                  class="a-wh-16"
                  @click="toBusinessAnalysisInfo(scope.row)"
                />
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <template slot="footerLabel" slot-scope="scope">
          <div v-if="scope.val != -1" class="a-flex-rfsc">
            <span class="a-fs-12 a-c-normal">筛选出</span>
            <span class="a-fs-16 a-c-master a-fw-b a-plr-7">{{ scope.val }}</span>
            <span class="a-fs-12 a-c-normal">条结果</span>
          </div>
        </template>
      </le-pagview>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      pageParam: {
        url: this.$Config.apiUrl.getStatIncomeList,
        method: "post",
        params: {
          ownerCompanyId: "",
          minHisOrderAmount: "",
          maxHisOrderAmount: "",
          minHisOrderNum: "",
          maxHisOrderNum: "",
          minAvgDeviceOrderAmount: "",
          maxAvgDeviceOrderAmount: "",
          statIncome: 0, //是否只显示参与收益的数据 1:参与收益的站点也统计 0:只显示所属站点统计
          stationOpenStartDate: "",
          stationOpenEndDate: "",
        },
        freshCtrl: 1,
      },
      minHisOrderAmount: "",
      maxHisOrderAmount: "",
      minAvgDeviceOrderAmount: "",
      maxAvgDeviceOrderAmount: "",
      optionsStatIncome: [
        {
          label: "自营",
          value: 0,
        },
        {
          label: "参与分成",
          value: 1,
        },
      ],
      fileUrl: "",
    };
  },
  watch: {
    minHisOrderAmount(newVal) {
      this.pageParam.params.minHisOrderAmount = newVal ? Number((newVal * 100).toFixed(0)) : "";
    },
    maxHisOrderAmount(newVal) {
      this.pageParam.params.maxHisOrderAmount = newVal ? Number((newVal * 100).toFixed(0)) : "";
    },
    minAvgDeviceOrderAmount(newVal) {
      this.pageParam.params.minAvgDeviceOrderAmount = newVal
        ? Number((newVal * 100).toFixed(0))
        : "";
    },
    maxAvgDeviceOrderAmount(newVal) {
      this.pageParam.params.maxAvgDeviceOrderAmount = newVal
        ? Number((newVal * 100).toFixed(0))
        : "";
    },
  },
  methods: {
    //获取设备列表
    setTableData(data, fileUrl) {
      this.tableData = data;
      this.fileUrl = fileUrl;
    },
    handlerRest() {
      this.minHisOrderAmount = "";
      this.maxHisOrderAmount = "";
      this.minAvgDeviceOrderAmount = "";
      this.maxAvgDeviceOrderAmount = "";
      this.pageParam.params = {
        ownerCompanyId: "",
        minHisOrderAmount: "",
        maxHisOrderAmount: "",
        minHisOrderNum: "",
        maxHisOrderNum: "",
        minAvgDeviceOrderAmount: "",
        maxAvgDeviceOrderAmount: "",
        statIncome: 0, //是否只显示参与收益的数据 1:参与收益的站点也统计 0:只显示所属站点统计
        stationOpenStartDate: "",
        stationOpenEndDate: "",
      };
      this.handlerSearch();
    },
    handlerSearch() {
      this.pageParam.freshCtrl++;
    },
    toBusinessAnalysisInfo(datas) {
      this.$router.push({
        path: "/businessAnalysis/businessAnalysis-info",
        query: {
          id: datas.incomeId,
          title: datas.incomeName,
          type: "project",
          revenueStatistics: JSON.stringify(datas),
        },
      });
    },
    exportfile() {
      if (!this.fileUrl) {
        this.$message.warning("暂无可导出文件");
        return;
      }
      this.$confirm("是否确认导出文件？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$Utils.downloadUrlFile(this.fileUrl, "项目经营数据列表");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .s-search-label {
  width: 100px;
}
</style>
